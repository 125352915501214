import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox } from 'src/components/ui/checkbox';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

interface FormCheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  name: string;
}

const FormCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  FormCheckboxProps
>(({ name, ...props }, ref) => {
  const { field } = useController({ name });

  return (
    <Checkbox
      {...props}
      ref={ref}
      checked={field.value}
      onCheckedChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
});

FormCheckbox.displayName = 'FormCheckbox';

export { FormCheckbox };
