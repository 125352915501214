import { extractFormErrors } from 'src/components/Form/Form.utils';
import {
  ChangeRequestStatusRequest,
  RequestStatus,
  requestStatus,
} from 'src/lib/services/api/request-api';

const useRequestStatusMap = () => {
  const getAvailableStatuses = (current?: RequestStatus): RequestStatus[] => {
    switch (current) {
      case requestStatus.queued:
        return [requestStatus.currentlyEditing];
      case requestStatus.currentlyEditing:
        return [requestStatus.delivered, requestStatus.queued];
      case requestStatus.delivered:
        return [requestStatus.currentlyEditing, requestStatus.complete];
      case requestStatus.complete:
        return [];
      default:
        return [];
    }
  };

  const canChangeStatus = (current?: RequestStatus | string): boolean => {
    return current !== requestStatus.complete;
  };

  return {
    canChangeStatus,
    getAvailableStatuses,
  };
};

const makeErrorsFromResponse = (e: any) => {
  return extractFormErrors(e) ?? {};
};

// Formik breaks when trying to access 'media.collection.0.property', cba debugging it
export const flattenMediaCollectionsErrors = (errors: any) => {
  const collections = ['default', 'project_files', 'thumbnails', 'captions', 'resizes'];

  collections.forEach((collection) => {
    const collectionErrors = errors.media?.[collection] ?? [];
    if (!collectionErrors.length) {
      return;
    }

    // Check if error is flat
    if (typeof collectionErrors[0] === 'string') {
      errors.media[collection] = collectionErrors;
      return;
    }

    // If it's not flat, build errors from properties
    const flat: string[] = [];
    collectionErrors.forEach((error: Record<string, string[]>) => {
      flat.push(...Object.values(error).flat());
    });
    errors.media[collection] = flat;
  });

  return errors;
};

export const mergeUploads = (uploads: Pick<ChangeRequestStatusRequest, 'media'>) => {
  return Object.values(uploads?.media ?? []).flat();
};

export { makeErrorsFromResponse, useRequestStatusMap };
