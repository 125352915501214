import React from 'react';
import { If } from 'src/components/If';
import { cn } from 'src/lib/utils';
import { useToggle } from 'src/lib/state-utils';
import { MediaModal } from 'src/components/MediaCard/media-modal';
import { FormMediaObject } from 'src/components/Form/S3UploadInput';
import { FileName } from 'src/components/MediaCard/file-name';
import { MediaViewer } from 'src/components/MediaCard/MediaViewer';

type Props = {
  media: FormMediaObject;
  showTitle?: boolean;
  canView?: boolean;
};

const MinimalMediaCard: React.FC<Props> = ({ media, showTitle, canView }) => {
  const [open, setOpen] = useToggle();

  return (
    <div className={cn('tw-relative tw-flex tw-w-[70px] tw-min-w-[70px] tw-max-w-full')}>
      <div className={cn('tw-w-full tw-rounded-lg tw-p-0')}>
        <div
          className={cn('tw-relative tw-h-12', canView && 'tw-cursor-pointer')}
          onClick={() => canView && setOpen(true)}
        >
          <MediaViewer media={media} />
        </div>

        <div
          className={cn('tw-flex tw-flex-col tw-justify-between tw-px-2 tw-pt-2 tw-text-text', {})}
        >
          {showTitle && (
            <a
              className={
                'tw-truncate tw-break-all tw-text-sm tw-font-medium tw-leading-normal !tw-underline'
              }
              href={media.index_url}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <FileName fileName={media.file_name} />
            </a>
          )}
          <If when={canView}>
            <MediaModal
              media={media}
              open={open}
              onOpenChange={(open) => {
                setOpen(open);
              }}
            />
          </If>
        </div>
      </div>
    </div>
  );
};

export { MinimalMediaCard };
