import React from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { useToggle } from 'src/lib/state-utils';

type EmojiPickerType = {
  onSelect?: (emoji: any) => void;
  children: any;
};

const EmojiPicker: React.FC<EmojiPickerType> = ({ onSelect, children, ...rest }) => {
  const [open, setOpen] = useToggle();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        align={'center'}
        side={'bottom'}
        className={'tw-w-auto tw-border-none tw-bg-transparent tw-p-0 tw-shadow-none'}
      >
        <Picker
          autoFocus
          {...rest}
          data={data}
          onEmojiSelect={(emoji: any) => {
            setOpen(false);
            onSelect?.(emoji);
          }}
          theme={'light'}
        />
      </PopoverContent>
    </Popover>
  );
};

export default EmojiPicker;
