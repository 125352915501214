import { QueryErrorResetBoundary } from '@tanstack/react-query';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Sheet,
  SheetCloseButton,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'src/components/ui/sheet';
import { Skeleton } from 'src/components/ui/skeleton';
import { CardErrorFallback } from 'src/features/fallback';
import { useRevisionChatCommentReplyStore } from './revision-chat-comment-reply.store';
import { useShallow } from 'zustand/react/shallow';
import RevisionChatCommentReplySheetContent from './revision-chat-comment-reply-sheet-content';
import { RevisionChatCommentReplyQueryProvider } from 'src/features/comments/use-request-reply-comments';

const RevisionChatCommentReplySheet = () => {
  const { t } = useTranslation();
  const { replyComment, setReplyComment } = useRevisionChatCommentReplyStore(
    useShallow(({ replyComment, setReplyComment }) => ({
      replyComment,
      setReplyComment,
    })),
  );

  return (
    <Sheet modal={false} open={!!replyComment}>
      <SheetContent className={'tw-flex tw-w-full tw-flex-col tw-gap-0'}>
        <SheetHeader>
          <SheetTitle>{t('Thread')}</SheetTitle>
          <SheetCloseButton onClick={() => setReplyComment(undefined)} />
        </SheetHeader>
        <div className={'tw-flex tw-h-full tw-flex-col tw-overflow-hidden'}>
          <div className={'tw-flex tw-h-full tw-grow tw-flex-col'}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary
                  fallbackRender={({ error, resetErrorBoundary }: any) => (
                    <CardErrorFallback
                      error={error}
                      resetErrorBoundary={resetErrorBoundary}
                      className={'tw-border-0 tw-shadow-none'}
                    />
                  )}
                  onReset={reset}
                >
                  <React.Suspense fallback={<RevisionChatCommentReplySheetSkeleton />}>
                    {!!replyComment?.id && (
                      <div className={'tw-relative tw-flex tw-h-full tw-flex-col tw-gap-6'}>
                        <RevisionChatCommentReplyQueryProvider
                          filters={{
                            sorts: [
                              {
                                id: 'created_at',
                                desc: true,
                              },
                            ],
                          }}
                          commentId={replyComment.id}
                        >
                          <RevisionChatCommentReplySheetContent replyComment={replyComment} />
                        </RevisionChatCommentReplyQueryProvider>
                      </div>
                    )}
                  </React.Suspense>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

const RevisionChatCommentReplySheetSkeleton: React.FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-px-6 tw-pb-6">
      {Array.from({ length: 12 }).map((_, index) => (
        <div key={index} className={'tw-space-y-3 tw-border-b tw-py-3'}>
          <Skeleton className={'tw-h-6 tw-w-32'} />
          <Skeleton className={'tw-h-6 tw-w-full'} />
        </div>
      ))}
    </div>
  );
};

export { RevisionChatCommentReplySheet };
