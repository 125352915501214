import React from 'react';
import { formatTime } from 'src/features/video-player/video-player-utils';
import { useVideoProgressState } from 'src/features/video-player/use-video-progress-store';
import { Clock2 } from 'lucide-react';
import { FormCheckbox } from 'src/components/Form/hook-form/form-checkbox';
import { cn } from 'src/lib/utils';
import { useFormContext } from 'react-hook-form';

type ElapsedTimeBadgeProps = {
  title: string;
  formattingOptions?: Parameters<typeof formatTime>[1];
  disabled?: boolean;
};

export const ElapsedTimeBadge: React.FC<ElapsedTimeBadgeProps> = ({
  formattingOptions,
  disabled,
  ...props
}) => {
  const { register } = useFormContext();
  const { playedSeconds } = useVideoProgressState();

  const formattedElapsed = formatTime(playedSeconds, formattingOptions);

  return (
    <div
      {...props}
      className={cn(
        'tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-brand-500 tw-bg-brand-200 tw-p-2 tw-text-text',
        disabled && 'tw-cursor-not-allowed tw-opacity-60',
      )}
    >
      <Clock2 size={16} />
      <span className={'tw-font-medium'}>{formattedElapsed}</span>
      <FormCheckbox
        disabled={disabled}
        id={'include_time'}
        className={cn('tw-border-brand-400')}
        {...register('include_time')}
      />
    </div>
  );
};
