import React from 'react';
import { If } from 'src/components/If';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { MediaObject } from 'src/api/services/MediaClient';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { MinimalMediaFormCard } from 'src/components/MediaCard/minimal-media-form-card';

type Props = {
  name: string;
};

const RevisionChatSelectedMedia: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const { getValues, control } = useFormContext();
  const { remove } = useFieldArray({
    name,
  });

  const mediaFields = useWatch({ control, name });

  const { isFailed } = useMediaUploadStore();
  const uploadsFailed = mediaFields.some((m: any) => isFailed(m.id));

  const { abort, removeUpload } = useMediaUploadStore(
    useShallow(({ abort, removeUpload }) => ({ abort, removeUpload })),
  );

  const deleteFile = (index: number) => {
    // useFieldArray fields are not meant to get form values, so we get values this way
    const mediaId = getValues(`${name}.${index}`).id;

    abort(mediaId); // abort upload
    remove(index); // remove from form

    removeUpload(mediaId); // remove from upload store
  };

  return (
    <If when={!!mediaFields.length}>
      <div className={'tw-flex tw-flex-wrap tw-gap-2'}>
        {mediaFields.map((m: MediaObject, index: number) => {
          const media = getValues(`${name}.${index}`);
          if (!MediaDeviceInfo || !media) {
            return;
          }
          return (
            <MinimalMediaFormCard
              key={m.id}
              media={media}
              onDelete={() => deleteFile(index)}
            />
          );
        })}
      </div>
      <If when={uploadsFailed}>
        <span className="text-danger invalid-feedback d-block">
          {t('Some files failed to upload, remove them and try again')}
        </span>
      </If>
    </If>
  );
};

export { RevisionChatSelectedMedia };
