import { Client, PaginatedResponse } from 'src/api/Client';
import {
  AdminCompaniesTableResponse,
  AdminCompanyResponse,
  CompaniesSelectResponse,
  UpdateCompanyNameRequest,
  UpdateCompanyStripeIdRequest,
} from './CompanyClient.types';
import { CompanyPersonnelIndexResponse } from '../CompanyPersonnelClient';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { AxiosRequestConfig } from 'axios';

class CompanyClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/companies`,
    get: `${this.http.baseUrl}/admin/companies/{model}`,
    assignEditor: `${this.http.baseUrl}/admin/companies/{model}/{subscriptionId}`,
    personnel: `${this.http.baseUrl}/companies/{model}/personnel`,
    invite: `${this.http.baseUrl}/companies/{model}/invite`,
    options: `${this.http.baseUrl}/admin/companies/options`,
    updateName: `${this.http.baseUrl}/admin/companies/{model}/update-name`,
    updateStripeId: `${this.http.baseUrl}/admin/companies/{model}/update-stripe-id`,
  };

  fetchCompanies = (
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<AdminCompaniesTableResponse>> => {
    return this.http.get(this.route.filter, { params });
  };

  fetchCompany = (model: string): Promise<AdminCompanyResponse> => {
    const url = this.buildUrl(this.route.get, { model });
    return this.http.get(url);
  };

  personnel = (
    model: string,
    params?: ApiQueryObject,
  ): Promise<PaginatedResponse<CompanyPersonnelIndexResponse>> =>
    this.http.get(this.buildUrl(this.route.personnel, { model }), { params });

  invite = (model: string, data: unknown): Promise<unknown> =>
    this.http.post(this.buildUrl(this.route.invite, { model }), data);

  options = (
    params: ApiQueryObject,
    config?: AxiosRequestConfig,
  ): Promise<PaginatedResponse<CompaniesSelectResponse>> =>
    this.http.get(this.route.options, { ...(config ?? {}), params });

  updateName = (model: string, data: UpdateCompanyNameRequest): Promise<void> =>
    this.http.put(this.buildUrl(this.route.updateName, { model }), data);

  updateStripeId = (
    model: string,
    data: UpdateCompanyStripeIdRequest,
    config?: AxiosRequestConfig,
  ): Promise<AdminCompanyResponse> =>
    this.http.put(this.buildUrl(this.route.updateStripeId, { model }), data, config);
}

export default CompanyClient;
