import { CommentResponse } from 'src/lib/services/api/comment-api';
import { create } from 'zustand';

const useRevisionChatCommentReplyStore = create<{
  replyComment: CommentResponse | undefined;
  setReplyComment: (replyComment: CommentResponse | undefined) => void;
}>((set) => ({
  replyComment: undefined,
  setReplyComment: (replyComment: CommentResponse | undefined) => set({ replyComment }),
}));

export { useRevisionChatCommentReplyStore };
