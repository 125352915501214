import { Api } from 'src/api';
import { httpConfig } from 'src/lib/modules/http';
import { AxiosRequestConfig } from 'axios';
import { url } from 'src/lib/utils';
import {
  ChangeEditorRequest,
  changeQAStatusRequest,
  ChangeRequestStatusRequest,
  FilteredRequestsOutcomesCountResponse,
  RequestActivityResponse,
  RequestOutcomeResponse,
  RequestResponse,
  RequestTranscriptResponse,
  UpdatePriorityRequest,
} from 'src/lib/services/api/request-api/request-client.types';
import { RevisionResponse } from 'src/lib/services/api/revision-api/revision-client.types';
import { PaginatedResponse } from 'src/api/Client';

const baseUrl = `${httpConfig.options.baseURL}/requests`;

const get = (model: string, config?: AxiosRequestConfig): Promise<RequestResponse> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}`, { model }), config);

const changeStatus = (
  model: string,
  data: ChangeRequestStatusRequest,
  config?: AxiosRequestConfig,
): Promise<RequestResponse> =>
  Api.http.put(
    url.insertArguments(`${httpConfig.options.baseURL}/admin/requests/{model}/change-status`, {
      model,
    }),
    data,
    config,
  );

const resolveAttention = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(url.insertArguments(`${baseUrl}/{model}/request-attention`, { model }), config);

const outcomes = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<RequestOutcomeResponse>> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/outcomes`, { model }), config);

export type RequestOutcomesPaginatedResponse = PaginatedResponse<RequestOutcomeResponse>;
export type RequestOutcome = RequestOutcomesPaginatedResponse['items'][number];

const storeOutcome = (
  model: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<RequestOutcome> =>
  Api.http.post(url.insertArguments(`${baseUrl}/{model}/outcomes`, { model }), data, config);

const complete = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(url.insertArguments(`${baseUrl}/{model}/complete`, { model }), {}, config);

/**
 * Not implemented
 * @param model
 * @param config
 */
const revisions = (model: string, config?: AxiosRequestConfig): Promise<RevisionResponse[]> =>
  Api.http.get(url.insertArguments(`${baseUrl}/{model}/revisions`, { model }), config);

type RequestRevisionsResponse = Awaited<ReturnType<typeof revisions>>;
type RequestRevisionsResponseItem = RequestRevisionsResponse[number];

const archive = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.delete(url.insertArguments(`${baseUrl}/{model}/archive`, { model }), config);

const restore = (model: string, config?: AxiosRequestConfig): Promise<void> =>
  Api.http.post(url.insertArguments(`${baseUrl}/{model}/restore`, { model }), {}, config);

const changeEditor = (
  model: string,
  data: ChangeEditorRequest,
  config?: AxiosRequestConfig,
): Promise<void> =>
  Api.http.put(`${httpConfig.options.baseURL}/admin/requests/${model}/change-editor`, data, config);

const updatePriority = (
  model: string,
  data: UpdatePriorityRequest,
  config?: AxiosRequestConfig,
): Promise<void> => Api.http.put(`${baseUrl}/${model}/update-priority`, data, config);

const getActivities = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<PaginatedResponse<RequestActivityResponse>> =>
  Api.http.get(`${baseUrl}/${model}/activities`, config);

const filteredRequestsOutcomesCount = (
  config?: AxiosRequestConfig,
): Promise<FilteredRequestsOutcomesCountResponse> =>
  Api.http.get(`${baseUrl}/filtered-requests-count`, config);

export const transcript = (
  model: string,
  config?: AxiosRequestConfig,
): Promise<RequestTranscriptResponse | null> =>
  Api.http.get(`${baseUrl}/${model}/transcript`, config);

export const attachTranscript = (
  model: string,
  mediaId: string,
  config?: AxiosRequestConfig,
): Promise<RequestTranscriptResponse> =>
  Api.http.post(`${baseUrl}/${model}/transcript/${mediaId}`, config);

export const changeQAStatus = (
  model: string,
  data: changeQAStatusRequest,
  config?: AxiosRequestConfig,
): Promise<void> =>
  Api.http.put(
    `${httpConfig.options.baseURL}/admin/requests/${model}/change-qa-status`,
    data,
    config,
  );

export const changeEditorNote = (
  model: string,
  data: { editor_note: string },
  config?: AxiosRequestConfig,
): Promise<RequestResponse> =>
  Api.http.put(
    `${httpConfig.options.baseURL}/admin/requests/${model}/change-editor-note`,
    data,
    config,
  );

export {
  get,
  changeStatus,
  resolveAttention,
  outcomes,
  storeOutcome,
  complete,
  archive,
  restore,
  changeEditor,
  updatePriority,
  getActivities,
  filteredRequestsOutcomesCount,
};
