import { Client, PaginatedResponse } from 'src/api/Client';
import { RevisionResponse, UpdateRevisionRequest } from './RevisionClient.types';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

class RevisionClient extends Client {
  public readonly route = {
    filter: `${this.http.baseUrl}/admin/revisions/{model}/filter`,
    get: `${this.http.baseUrl}/admin/revisions/{model}`,
    store: `${this.http.baseUrl}/admin/revisions/{model}`,
    destroy: `${this.http.baseUrl}/admin/revisions/{model}`,
  };

  filter = (params: ApiQueryObject): Promise<PaginatedResponse<RevisionResponse>> => {
    return this.http.get(this.route.filter, { params });
  };

  get = (revisionId: string): Promise<RevisionResponse> => {
    return this.http.get(this.buildUrl(this.route.get, { revisionId }));
  };

  destroy = (model: string): Promise<void> => {
    return this.http.delete(this.buildUrl(this.route.destroy, { model }));
  };

  update = (model: string, data: UpdateRevisionRequest): Promise<void> =>
    this.http.put(this.buildUrl(this.route.store, { model }), data);
}

export default RevisionClient;
