import React from 'react';
import { Loader2, LucideProps } from 'lucide-react';
import { cn } from 'src/lib/utils';

const ButtonLoader: React.FC<
  LucideProps & {
    loading?: boolean;
  }
> = ({ loading = false, className, ...props }) => {
  if (!loading) {
    return null;
  }

  return (
    <>
      <Loader2 className={cn('tw-ms-2 tw-h-5 tw-w-5 tw-animate-spin', className)} {...props} />
      <span className={'tw-sr-only'}>Loading...</span>
    </>
  );
};

const SpinnerLoader: React.FC<
  LucideProps & {
    loading?: boolean;
  }
> = ({ loading = false, className, ...props }) => {
  if (!loading) {
    return null;
  }

  return (
    <>
      <Loader2
        className={cn('tw-h-5 tw-w-5 tw-animate-spin tw-text-orange', className)}
        {...props}
      />
      <span className={'tw-sr-only'}>Loading...</span>
    </>
  );
};

export { ButtonLoader, SpinnerLoader };
