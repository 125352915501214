import React, { useRef } from 'react';
import { useRevisionChatCommentReplyQueryContext } from 'src/features/comments/use-request-reply-comments';
import { CommentResponse } from 'src/lib/services/api/comment-api';
import RevisionChatCommentReplySheetComments from './revision-chat-comment-reply-sheet-comments';
import { RevisionChatInputForm } from './revision-chat-input-form';
import { useRequestCommentsContext } from 'src/features/comments/use-request-comments';

const RevisionChatCommentReplySheetContent: React.FC<{ replyComment: CommentResponse }> = ({
  replyComment,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const { replyCommentMutation } = useRevisionChatCommentReplyQueryContext();
  const { invalidate } = useRequestCommentsContext();

  const scrollToBottom = () => {
    if (!scrollRef.current || !scrollRef.current?.scrollHeight) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  return (
    <>
      <RevisionChatCommentReplySheetComments ref={scrollRef} replyComment={replyComment} />
      <RevisionChatInputForm
        className={'tw-px-6 tw-pb-6'}
        commentMutation={replyCommentMutation}
        replyCommentId={replyComment.id}
        onSubmit={() => {
          invalidate();
          setTimeout(() => scrollToBottom(), 1);
        }}
      />
    </>
  );
};

export default RevisionChatCommentReplySheetContent;
