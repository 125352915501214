import React, { useRef } from 'react';
import { Smile } from 'lucide-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/ui/button';
import EmojiPicker from 'src/components/ui/emoji-picker';
import { Textarea } from 'src/components/ui/textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommentResponse } from 'src/lib/services/api/comment-api';
import { object, string } from 'yup';
import { useRequestCommentsContext } from 'src/features/comments/use-request-comments';
import { useToast } from 'src/components/ui/use-toast';

type Props = {
  comment: CommentResponse;
  onCancel: () => void;
  onSuccess: () => void;
};

const validationSchema = object().shape({
  comment: string().required(),
});

const RevisionChatEditCommentForm: React.FC<Props> = ({ comment, onCancel, onSuccess }) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { updateCommentMutation } = useRequestCommentsContext();

  const form = useForm<{ comment: string }>({
    defaultValues: { comment: comment.comment },
    /* eslint-disable */
    //@ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const commentInput = form.watch('comment');

  const handleEmojiSelect = (emoji: any) => {
    if (!textareaRef.current) {
      return;
    }
    const cursorPos = textareaRef.current?.selectionStart;
    const textBeforeCursor = commentInput?.slice(0, cursorPos);
    const textAfterCursor = commentInput?.slice(cursorPos);

    const newText = textBeforeCursor + emoji.native + textAfterCursor;
    form.setValue('comment', newText);

    // Set cursor after inserted emoji
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.selectionStart = cursorPos + emoji.native.length;
      }
    }, 0);
  };

  const submit = async (values: { comment: string }) => {
    updateCommentMutation.mutate([comment.id, { comment: values.comment }], {
      onSuccess: () => onSuccess(),
      onError: (e) => {
        console.error('Failed to update comment', e);
        toast({
          description: t('Oops, something went wrong, please try again later.'),
          variant: 'destructive',
        });
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <Textarea
          id={'comment'}
          rows={4}
          placeholder={t('Type a message...')!}
          {...form.register('comment')}
          ref={(e) => {
            form.register('comment').ref(e);
            textareaRef.current = e;
          }}
        />
        <div className={'tw-mt-2 tw-flex tw-gap-2'}>
          <EmojiPicker onSelect={handleEmojiSelect}>
            <Button type={'button'} variant={'outline'} size={'sm'}>
              <Smile size={18} />
            </Button>
          </EmojiPicker>
          <div className={'tw-ml-auto tw-flex tw-gap-2'}>
            <Button onClick={onCancel} size={'sm'} variant={'outline'}>
              {t('Cancel')}
            </Button>
            <Button size={'sm'} variant={'brand'}>
              {t('Save')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { RevisionChatEditCommentForm };
