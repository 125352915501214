import React from 'react';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';
import { commentClient, CommentResponse } from 'src/lib/services/api/comment-api';
import { useMakeInfiniteQuery } from 'src/api/queries';
import { useProfile } from 'src/models/profile';
import { ApiQueryObject } from 'src/lib/services/api-query-params';

type ApiResponse = Awaited<ReturnType<typeof commentClient.filterCommentReplies>>;
type ReplyCommentMutationRequestParameters = Parameters<typeof commentClient.reply>;
type ReactCommentMutationRequestParameters = Parameters<typeof commentClient.react>;

const makeQueryKey = (commentId: string) => ['comments', commentId];

export const useRequestReplyComments = (
  commentId: string,
  filters: ApiQueryObject,
  queryOptions?: Parameters<typeof useMakeInfiniteQuery<ApiResponse>>[0],
) => {
  const client = useQueryClient();
  const profileData = useProfile({
    enabled: false,
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, ...context } =
    useMakeInfiniteQuery<ApiResponse>(
      {
        queryKey: makeQueryKey(commentId),
        queryFn: ({ signal, ...context }) => {
          return commentClient.filterCommentReplies(
            commentId,
            {
              ...filters,
              page: context.pageParam,
            },
            {
              signal,
            },
          );
        },
        getNextPageParam: (lastPage) => {
          return lastPage.meta.current_page < lastPage.meta.last_page
            ? lastPage.meta.current_page // current_page comes as a number of the next page from backend
            : undefined;
        },
        staleTime: 10 * 1000,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        ...(queryOptions ?? {}),
      },
      filters,
    );

  const replyCommentMutation = useMutation({
    mutationFn: (args: ReplyCommentMutationRequestParameters) => commentClient.reply(...args),
    onMutate: async (args: ReplyCommentMutationRequestParameters) => {
      const tempId = Math.random().toString(36).slice(2);

      client.setQueryData<InfiniteData<Partial<ApiResponse>>>(['comments', args[0]], (prev) => {
        if (!prev) {
          return prev;
        }

        const next = { ...prev };
        const firstPage = next.pages[0];
        if (!firstPage.items) {
          return next;
        }

        firstPage.items.unshift({
          ...args[1],
          id: tempId,
          sender: {
            me: true,
            full_name: profileData.fullName,
            type: profileData.type,
          },
          created_at: new Date().toISOString(),
        } as any);

        return next;
      });
    },
    onSettled: (c, e, args) => {
      client.invalidateQueries<InfiniteData<Partial<ApiResponse>>>(['comments', args[0]]);
    },
  });

  const removeReplyCommentMutation = useMutation({
    mutationFn: (comment: string) => commentClient.remove(comment),
    onMutate: async (comment) => {
      client.setQueryData<InfiniteData<Partial<ApiResponse>>>(['comments', commentId], (prev) => {
        if (!prev) {
          return prev;
        }

        const next = { ...prev };

        const page = next.pages.find((p) => p.items?.find((c) => c.id === comment));

        if (!page) {
          return prev;
        }

        page.items = page.items!.filter((c) => c.id !== comment);

        return next;
      });
    },
    onSettled: () => {
      client.invalidateQueries(['comments', commentId]);
    },
  });

  const reactReplyCommentMutation = useMutation({
    mutationFn: (args: ReactCommentMutationRequestParameters) => commentClient.react(...args),
    onSettled: () => {
      client.invalidateQueries<InfiniteData<Partial<ApiResponse>>>(['comments', commentId]);
    },
  });

  const comments = data?.pages.reduce((a, b) => a.concat(b.items), [] as CommentResponse[]) ?? [];

  const invalidate = () => client.invalidateQueries(makeQueryKey(commentId));

  const isLoadingMore = isFetchingNextPage;

  const canLoadMore = hasNextPage;

  const meta = data?.pages[0]?.meta;

  const hasComments = comments.length > 0;

  return {
    context,
    comments,
    hasComments,
    fetchNextPage,
    isLoadingMore,
    canLoadMore,
    meta,
    isLoading,
    replyCommentMutation,
    removeReplyCommentMutation,
    reactReplyCommentMutation,
    invalidate,
  };
};

const Context = React.createContext<any>(undefined);

export const RevisionChatCommentReplyQueryProvider: React.FC<
  React.PropsWithChildren<{
    commentId: string;
    filters?: ApiQueryObject & { revision_index?: number };
  }>
> = ({ commentId, children, filters = {} }) => {
  const context = useRequestReplyComments(commentId, filters, {
    suspense: true,
    retry: 1,
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useRevisionChatCommentReplyQueryContext = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('RevisionChatCommentQuery must be used within a RevisionChatCommentQuery');
  }

  return context;
};
